<template>
  <div>
    <Container class="bg-black-550 pb-10">
      <div class="max-w-7xl mx-auto relative">
        <Navbar class="relative z-20" isAlternate />
        <div class="mt-14 relative z-20">
          <div class="grid grid-cols-2 gap-x-14">
            <div>
              <h1 class="text-white">
                Become a <br />
                UI/UX Designer
              </h1>
              <p class="text-xl text-grey-450 mt-4">
                We are uniquely positioned to capitalize on this tremendous
                opportunity to deliver cutting-edge and culturally competent
                software to financial institutions.
              </p>
              <form @submit.prevent="" class="space-y-4 mt-6">
                <div class="space-y-4 mt-6">
                  <FormInput
                    name="first_name"
                    placeholder="First Name"
                    type="text"
                  />
                  <FormInput
                    name="email"
                    placeholder="Enter your email"
                    type="email"
                  />
                </div>

                <div class="flex space-x-4">
                  <button type="submit" class="w-full btn btn--grey">
                    Enrol now
                  </button>
                  <div class="w-full btn btn--grey">Download Syllabus only</div>
                </div>
              </form>
              <div class="space-y-4 mt-10 mb-20">
                <div class="flex items-start">
                  <div class="mr-4">
                    <Checkbox />
                  </div>
                  <p class="text-grey-350">
                    The average base pay for a UX designer in the U.S. is $115k!
                  </p>
                </div>
                <div class="flex items-start">
                  <div class="mr-4">
                    <Checkbox />
                  </div>
                  <p class="text-grey-350">
                    Next program start date, 11th June 2020
                  </p>
                </div>
                <div class="flex items-start">
                  <div class="mr-4">
                    <Checkbox />
                  </div>
                  <p class="text-grey-350">
                    Some companies that use these technologies regularly
                    include: Google, Facebook, Yahoo, eBay and Amazon
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div class="mt-20">
                <iframe
                  class="w-full h-96 rounded"
                  src="https://www.youtube.com/embed/NIkg3Ui7ITY"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
        <Illustration class="absolute -right-32 2xl:-right-16 top-0 z-10" />
      </div>
    </Container>
    <Container class="bg-accent pt-44 pb-28">
      <h1 class="text-4xl font-normal">What you'll learn</h1>
      <div class="w-5/6 space-y-10 mt-14">
        <div v-for="item in whatToLearn" :key="item.title">
          <h5 class="font-semibold">{{ item.title }}</h5>
          <p class="text-2xl text-grey-350 mt-6">{{ item.description }}</p>
        </div>
      </div>
    </Container>
    <Container class="bg-white pt-44 pb-28">
      <h1 class="text-4xl font-normal">All Our Programs Include</h1>
      <div class="w-5/6 space-y-10 mt-14">
        <div v-for="program in programs" :key="program.title">
          <h5 class="font-semibold">{{ program.title }}</h5>
          <p class="text-2xl text-grey-350 mt-6">{{ program.description }}</p>
        </div>
      </div>
    </Container>
    <FAQ />
    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/blocks/Navbar";
import Container from "@/components/blocks/Container";
import Checkbox from "../../public/svg/checkbox.svg";
import Footer from "@/components/blocks/Footer";
import FormInput from "@/components/blocks/FormInput";
import Illustration from "../../public/svg/programe-bg.svg";
import FAQ from '@/components/blocks/FAQ'

export default {
  name: "Programs",
  components: {
    Navbar,
    Container,
    Checkbox,
    Footer,
    FormInput,
    Illustration,
    FAQ
  },
  data() {
    return {
      whatToLearn: [
        {
          title: "UX Fundamentals & Design Research",
          description:
            "Product design starts with understanding the needs of users, which is gathered through comprehensive research. Learn the core principles of human-centered design and how to appropriately scope a design problem. Understand how to empathize with users when performing user research, including how to conduct in-depth interviews and create quantitative surveys, and use research data to uncover opportunities. You’ll then apply psychology to design sketches, keeping the end-user in mind.",
        },
        {
          title: "Concept to Low-Fidelity Prototyping",
          description:
            "Product design starts with understanding the needs of users, which is gathered through comprehensive research. Learn the core principles of human-centered design and how to appropriately scope a design problem. Understand how to empathize with users when performing user research, including how to conduct in-depth interviews and create quantitative surveys, and use research data to uncover opportunities. You’ll then apply psychology to design sketches, keeping the end-user in mind.",
        },
        {
          title: "High-Fidelity Prototyping to Post-Launch Analysis",
          description:
            "Product design starts with understanding the needs of users, which is gathered through comprehensive research. Learn the core principles of human-centered design and how to appropriately scope a design problem. Understand how to empathize with users when performing user research, including how to conduct in-depth interviews and create quantitative surveys, and use research data to uncover opportunities. You’ll then apply psychology to design sketches, keeping the end-user in mind.",
        },
      ],
      programs: [
        {
          title: "Real-world projects from industry experts",
          description:
            "With real world projects and immersive content built in partnership with top tier companies, you’ll master the tech skills companies want.",
        },
        {
          title: "Career service",
          description:
            "With real world projects and immersive content built in partnership with top tier companies, you’ll master the tech skills companies want.",
        },
        {
          title: "Technical mentor support",
          description:
            "Our knowledgeable mentors guide your learning and are focused on answering your questions, motivating you and keeping you on track.",
        },
        {
          title: "Flexible learning program",
          description:
            "Get a custom learning plan tailored to fit your busy life. Learn at your own pace and reach your personal goals on the schedule that works best for you.",
        },
      ],
      
    };
  },
};
</script>

<style lang="scss" scoped>
</style>