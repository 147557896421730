<template>
  <Container class="bg-accent pt-44 pb-48">
    <div class="grid grid-cols-3 gap-14">
      <div>
        <h1>Frequently Asked Questions</h1>
        <p class="text-xl text-grey-350 mt-6">
          Our schools are designed to help you acquire skills with real-world
          applications. With project based active learning, your mastery of the
          topics translates into skills that put you in the top percentile in
          the labour market.
        </p>
      </div>
      <div class="col-start-2 col-span-3">
        <div class="space-y-6">
          <div
            class="flex justify-between items-center pb-4 border-b border-gray-400"
            v-for="question in questions"
            :key="question.title"
          >
            <h5 class="font-normal">{{ question.title }}</h5>
            <Chevron class="cursor-pointer" />
          </div>
        </div>
      </div>
    </div>
  </Container>
</template>

<script>
import Chevron from "../../../public/svg/chevron.svg";
import Container from '@/components/blocks/Container'

export default {
    name: 'FAQ',
  data() {
    return {
      questions: [
        {
          title: "Why should I enroll?",
        },
        {
          title: "What is your refund policy?",
        },
        {
          title: "What if I need accommodations",
        },
        {
          title: "When does the program start?",
        },
        {
          title: "What are the requirements to join the program?",
        },
        {
          title: "What will I learn?",
        },
        {
          title: "How do I download videos for offline viewing?",
        },
        {
          title: "What if I have a question that isn't answered here?",
        },
        {
          title: "What software and versions will I need in this program?",
        },
        {
          title: "What software and versions will I need in this program?",
        },
      ],
    };
  },
  components: {
      Chevron,
      Container
  },
};
</script>

<style lang="scss" scoped>
</style>